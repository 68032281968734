@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BRFirma";
  src: url("./assets/fonts/BR\ Firma\ Font\ Family/BRFirma-Regular.ttf");
}
@font-face {
  font-family: "BRFirmaBold";
  src: url("./assets/fonts/BR\ Firma\ Font\ Family/BRFirma-Bold.ttf");
}
@font-face {
  font-family: "Helvetica_Neue";
  src: url("./assets/fonts/Helvetica-Neue-Font/Helvetica Neue Medium Extended/Helvetica\ Neue\ Medium\ Extended.otf");
}

@font-face {
  font-family: "BRF";
  src: url("./assets/fonts/BR\ Firma\ Font\ Family/BRFirma-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* } */

@font-face {
  font-family: "BRF";
  src: url("./assets/fonts/BR\ Firma\ Font\ Family/BRFirma-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "BRF";
  src: url("./assets/fonts/BR\ Firma\ Font\ Family/BRFirma-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "BRF";
  src: url("./assets/fonts/BR\ Firma\ Font\ Family/BRFirma-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Nova+Flat&display=swap");

@font-face {
  font-family: "Poppins"; /* Provide a custom name for your font */
  src: url("./assets/fonts/Poppins-Font/Poppins-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "BRSonoma"; /* Provide a custom name for your font */
  src: url("./assets/fonts/BR-Sonoma-font-family/BRSonoma-Regular-BF654c45266c042.otf")
    format("otf");
}

* {
  border-color: #eeeff1;
}

body {
  margin: 0;
  font-family: "BRFirma", system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply light-bg;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  .test {
    @apply border border-red-500;
  }

  .light-bg {
    background-color: rgba(var(--primary-color), 0.03);
  }

  /* [9:47 AM, 11/20/2023] +234 817 000 0560: <h1></h1>	Bold 2.125rem (34px)
  [9:47 AM, 11/20/2023] +234 817 000 0560: <h2></h2>	Bold 1.875rem (30px)
  [9:47 AM, 11/20/2023] +234 817 000 0560: <h3></h3>	Bold 1.5rem (24px)
  [9:47 AM, 11/20/2023] +234 817 000 0560: <h4></h4>	Bold 1.25rem (20px)
  [9:48 AM, 11/20/2023] +234 817 000 0560: <h5></h5>	Bold 1.125rem (18px)
  [9:48 AM, 11/20/2023] +234 817 000 0560: <h6></h6>	Bold 1rem (16px) */
  h1,
  .h1 {
    @apply text-4xl sm:text-6xl font-['BRFirmaBold'] font-bold leading-[101%] text-[#1C2A4B];
  }
  h2,
  .h2 {
    @apply text-3xl sm:text-5xl font-['BRFirmaBold'] font-bold leading-[101%] text-[#1C2A4B];
  }
  h3,
  .h3 {
    @apply text-xl sm:text-4xl font-['BRFirmaBold'] font-bold leading-[101%] text-[#1C2A4B];
  }
  h4,
  .h4 {
    @apply text-base sm:text-[30px] font-['BRFirmaBold'] font-bold leading-[101%] text-[#1C2A4B];
  }
  h5,
  .h5 {
    @apply text-sm sm:text-xl font-['BRFirmaBold'] font-bold leading-[101%] text-[#1C2A4B];
  }
  h6,
  .h6 {
    @apply text-xs sm:text-base font-['BRFirmaBold'] font-bold leading-[101%] text-[#1C2A4B];
  }

  p {
    @apply font-thin !text-[#818083];
  }

  table, th, td {
    box-sizing: border-box;
  }
  
  .bg-emptypage {
    background: url("./assets/images/cancelled-ticket\ 1.png") no-repeat;
    background-position: right -10% bottom 0;
    background-size: contain;
  }
  .bg-bubble {
    background: url("./assets/icons/green\ bubbles.svg") no-repeat;
    background-position: left 0 bottom 0;
    background-size: contain 50%;
  }
  .bg-bubble-warn {
    background: url("./assets/icons/Yellow\ bubbles.svg") no-repeat;
    background-position: left 0 bottom 0;
    background-size: contain 50%;
  }
  .bg-frame {
    background: url("./assets/images/Frame\ 2609999.svg") no-repeat;
    background-size: cover;
  }

  .btn {
    @apply rounded-md border bg-primary/80 text-white p-2 px-4 cursor-pointer font-normal;
  }
  .btn.disabled {
    @apply text-primary/30 font-bold cursor-not-allowed;
  }
  .btn-small {
    @apply btn text-sm;
  }
  .btn-outlined {
    @apply btn bg-primary/0;
  }
  .btn-light {
    @apply btn bg-primary/10 text-primary/80;
  }

  .btn-theme {
    @apply !bg-theme1 !text-secondary !capitalize flex gap-2 p-2 !px-4 cursor-pointer font-normal;
  }
  .btn-theme-light {
    @apply !bg-secondary !text-primary/60 p-2 !px-4 flex gap-2 !shadow-none hover:shadow !capitalize cursor-pointer font-normal;
  }

  .content-max-w {
    @apply max-w-[830px];
  }

  .menuItem {
    @apply flex flex-col bg-secondary rounded-lg p-2;
  }

  .pd-md {
    @apply px-6 sm:px-16 py-10;
  }
  .px-md {
    @apply px-6 sm:px-16;
  }

  .title {
    @apply relative;
  }
  .title:hover::after {
    content: attr(custom-title);
    @apply bg-secondary mx-4 shadow-md shadow-primary/40 text-sm text-primary font-normal p-2 rounded-md; /* Example styles for the ::after element */
    position: absolute;
    top: 0;
    left: 100%;
  }
  .title.title-hide::after {
    content: "";
    @apply hidden;
  }

  .error {
    @apply bg-red-300 px-2 py-1 rounded-md text-red-800 font-bold;
  }
  .success {
    @apply bg-[#C4E9E3] px-2 py-1 rounded-md border-[#1EA994] text-[#0B662C] font-bold;
  }
  .warn {
    @apply bg-orange-300 px-2 py-1 rounded-md text-orange-800 font-bold;
  }
  .triumph {
    @apply bg-blue-300 px-2 py-1 rounded-md text-blue-700 font-bold;
  }

  .flight {
    @apply text-[#2970F8] bg-[#EAF2FA];
  }
  .tour {
    @apply text-[#844AF9] bg-[#E8DDFF];
  }
  .stay {
    @apply text-[#7F3E00] bg-[#FFEFE0];
  }

  .vector-bg {
    background-image: url(./assets/images/bg-vector.png);
    background-size: cover;
  }
}

@layer components {
  .card {
    @apply bg-secondary p-6 sm:p-16 rounded-3xl;
  }

  .tooltip {
    @apply bg-[#D9A513]/20 border-l-[10px] border-l-[#D9A513] text-[#A97500] p-2;
  }
  .tooltip.error {
    @apply bg-[#ea4545]/20 border-l-[10px] border-l-[#ea4545] text-[#ea4545];
  }
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}

textarea {
  resize: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  padding-inline: 4vw;
  z-index: 999;
}

.sidebar.open {
  left: 0;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  --c: no-repeat radial-gradient(farthest-side, #1e61dc 92%, #0000);
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  animation: l12 1s infinite;
}
.loader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear;
}

@keyframes l12 {
  100% {
    transform: rotate(0.5turn);
  }
}

.draw-line {
  animation: drawLine 300ms ease-in-out forwards;
}

@keyframes drawLine {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: none; /* Remove border from all cells */
  text-align: left;
  padding: 8px;
}

/* Add border to cells except for the first row and first column */
td:not(:first-child),
th:not(:first-child) {
  border-left: 1px solid #dddddd;
}

/* Add border to cells except for the first row */
td,
th:not(:first-child) {
  border-top: 1px solid #dddddd;
}

@keyframes uncollapse1 {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 200vh;
    opacity: 1;
  }
}

@keyframes uncollapse {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 300px;
    opacity: 1;
  }
}

.uncollapse {
  animation: uncollapse 0.5s ease-out forwards;
  overflow: hidden;
}

.uncollapse1 {
  animation: uncollapse1 0.5s ease-out forwards;
  overflow: hidden;
}

/* HTML: <div class="loader"></div> */
.loaderSearch {
  width: 20px;
  --b: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #1e61dc) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}

.buttonLoader {
  width: 20px;
  --b: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #ffffff) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
.buttonLoader.primary {
  background: conic-gradient(#fff 10%, #000) content-box;
}

.primaryLoader {
  width: 20px;
  --b: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #1e61dc) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}
